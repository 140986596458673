import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICurrency } from 'app/Interface/provider';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CurrencyService {
    private endpoints = {
        getCurrencies: environment.backendApi + '/api/Currency/GetAllAsync',
        getCurrencyById: environment.backendApi + '/api/Currency/Get?id=',
    };

    constructor(private http: HttpClient) {}

    /**
     * @description Get all Currencies
     * @param  {string} search
     * @returns {Observable<Currency[]>}
     */
    getAll(search: string): Observable<ICurrency[]> {
        const filter = '?skip=0&take=0&search=' + search;
        return this.http.post(this.endpoints.getCurrencies + filter, null).pipe(map((result: any) => result.Data[0]));
    }

    /**
     * @description Get currency by id
     * @param {number} currencyId
     * @returns {Observable<ICurrency>}
     */
    getById(currencyId: number): Observable<ICurrency> {
        return this.http.post(this.endpoints.getCurrencyById + currencyId, null).pipe(map((result: any) => result.Data[0]));
    }
}
