import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';

import { TemplateType, EntityType, DBOperation } from '../../mix/enum';
import { AppGlobalDataService } from '../../services/app-global-data.service';
@Injectable()
export class SidebarService {
    constructor(private _http: HttpClient, private _appdata: AppGlobalDataService) {}

    getLogo(uri: string): Observable<any> {
        let options = this._appdata.getDefaultRequestOptionsHttpClient(DBOperation.select);
        return this._http.get(this._appdata.getSafeEndpoint(uri), options).pipe(
            map((response: any) => {
                return this._appdata.getResponseHttpClient(response);
            }),
            catchError((response: any) => <any>this.handleError(response))
        );
    }

    private handleError(error: Response) {
        return observableThrowError('Server Error');
    }
}
