export enum Language {
    spanish = 'es',
    english = 'en',
}

export enum DBOperation {
    create = 1,
    update = 2,
    delete = 3,
    patch = 3,
    view = 4,
    select = 5,
    post_query = 6,
    fast_change = 7,
    special = 8,
    files = 9,
    activity = 10,
    sublist = 11,
    incidence = 12,
    undoActivate = 13,
    download = 14,
    referenceAction = 15,
    scaleModal = 16,
}

export interface BtnAction {
    operation: DBOperation;
    btn_class?: string;
    faicon_class?: string;
    name?: string;
    title?: string;
    entityTypeDependent?: EntityType;
    availableDependents?: any[];
    availableMasters?: number[];
    conditional?: boolean;
    visible?: boolean;
    enableConditionalButton?: boolean;
    btnActionConditional?: BtnActionConditional[];
    colored?: boolean;
}

export interface BtnActionConditional {
    conditionalPropertyVisibility?: string;
    conditionalValueVisibility?: any;
    reverseCondition?: boolean;
}

export interface AutocompleteConditionalClass {
    typeCondition?: typeConditionAutoCompleteAction;
    typeDate?: typeConditionAutoCompleteDate;
    applyClass?: string;
    comparePropertyOne?: any;
    comparePropertyTwo?: any;
    compareValue?: any;
}

export interface IChangeTabDetect {
    entityType: EntityType;
    objectId: number;
}

export enum typeConditionAutoCompleteAction {
    fixed = 0,
    compare = 1,
}

export enum typeConditionAutoCompleteDate {
    date = 0,
}

export interface ChkAction {
    avaliable: boolean;
    conditional?: boolean;
    chkActionConditional: ChkActionConditional;
}

export interface ChkActionConditional {
    conditionalPropertyVisibility?: string;
    conditionalValueVisibility?: any;
}

export enum IconClassModel {
    // project = "fa fa-sitemap",
    project = 'format_shapes',
    person = 'person_outline',
    user = 'person',
    vessel = 'directions_boat',
    voyage = 'fa fa-anchor',
    //crew = "fa fa-street-view",
    crew = 'airline_seat_recline_extra',
    group = 'group',
    provider = 'fa fa-exchange',
    port = 'fa fa-trello',
    flight = 'airplanemode_active',
    service = 'fa fa-handshake-o',
    type_service = 'fa-sign-language',
    client_provider_tariff = 'fa fa-tag',
    general_provider_tariff = 'fa fa-ticket',
    reservation = 'playlist_add_check',
    maintenance = 'fa fa fa-cogs',
    quotation = 'pages',
    pricelist = 'format_list_bulleted',
    default = 'fa fa-info-circle',
    activity = 'touch_app',
    notification = 'notifications',
    type_document = 'fa-file-text',
    stock = 'cast',
    rental = 'style',
    address = 'fa fa-address-book',
    incidence = 'warning',
    driver = 'fa fa-drivers-license-o',
    invoice = 'fa fa-file-text-o',
    bi = 'fa fa-bar-chart',
}

export enum WrappedType {
    default = 0,
    element = 1,
    list = 2,
    hlist = 3,
    manage = 4,
    cudform = 5,
    custom = 6,
    manageConditional = 7,
    reservationCustomer = 8,
    reservationMaintenance = 9,
    modal = 10,
}

export enum TemplateType {
    project = 1,
    user = 2,
    crew = 3,
    voyage = 4,
    maintenance = 5,
    vessel = 6,
    provider = 7,
    port = 8,
    person = 9,
    flight = 10,
    group = 11,
    chart = 12,
    service = 13,
    type_service = 14,
    administration = 15,
    client_provider_tariff = 16,
    general_provider_tariff = 17,
    reservation = 18,
    reservation_service = 19,
    config_crew_reservations = 20,
    quotation = 21,
    pricelist = 22,
    activity = 23,
    type_document = 24,
    type_document_rome = 25,
    notification = 26,
    stock = 27,
    rental = 28,
    excel = 29,
    address = 30,
    cartificador = 31,
    incidence = 32,
    delegated_reservation = 33,
    provider_reservation = 34,
    provider_tariffs = 35,
    driver = 36,
    config_crew_reservations_faluas = 37,
    officialOrganization = 38,
    invoicing = 39,
    baseRate = 40,
    rates = 41,
    management = 42,
    projectCategories = 43,
    genericReservation = 44,
    bi = 45,
    myReservations = 46,
    autoInvoice = 47,
    crewManagement = 48,
    onsigner = 49,
    offsigner = 50,
}

export enum EntityType {
    project = 1,
    user = 2,
    crew = 3,
    voyage = 4,
    maintenance = 5,
    vessel = 6,
    provider = 7,
    port = 8,
    country = 9,
    person = 10,
    client = 11,
    division = 12,
    flight = 13,
    gperson = 14,
    voyage_movement = 15,
    line = 16,
    custprov_grm = 17,
    group = 18,
    signer_movement = 19,
    service = 20,
    type_service = 21,
    administration = 22,
    client_provider_tariff = 23,
    general_provider_tariff = 24,
    custprov_vat_grm = 25,
    custprov_fiscal_grm = 26,
    custprov_branch_grm = 27,
    reservation = 28,
    reservation_service = 29,
    quotation = 30,
    pricelist = 31,
    activity = 32,
    type_document = 33,
    type_document_rome = 34,
    notification = 35,
    stock = 36,
    rental = 37,
    address = 38,
    incidence = 39,
    driver = 40,
    user_grm = 41,
    loginProvider = 42,
    officialOrganization = 43,
    reservation_falua = 44,
    reference = 45,
    baseRate = 46,
    currency = 47,
    maintenanceReservation = 48,
    reservationExtraService = 49,
    categories = 50,
    origins = 51,
    reservationCode = 52,
    customer = 53,
    bi = 54,
    providerDriver = 55,
    autoInvoice = 56,
    custprov_vat_rome_code = 57,
    custprov_name_fical_name = 58,
    airport = 59,
    documentId = 60,
    crewManagement = 61,
    loginDelegatedProvider = 62,
    agent = 63,
}

export enum CookieKeys {
    user = 'usr_data',
    login = 'enter',
    token = 'tkn',
    temp = 'tmp',
    pending_notification = 'notification',
    language = 'lang',
    permisissions = 'targets',
    last_fly_tab = 'tab',
    maintenance_tabs_order = 'otabs',
    entityChanges = 'entityChanges',
    exceptions = 'exceptions',
    show_whats_new = 'show_whats_new',
    fixedValueDivision = 'fixedValueDivision',
    currency = 'currency',
    languageList = 'languageList',
}

export enum EModalActionType {
    create = 'create',
    delete = 'delete',
    edit = 'edit',
    split = 'split',
    changeTypeDocument = 'changeTypeDocument',
}

export enum AvailableEnums {
    StateType = 0,
    ServiceTarget = 1,
    ServiceCategory = 2,
    ServicePaymentType = 3,
    SignerMovementType = 4,
    CrewStateType = 5,
    DockMovementType = 6,
    EntityExecStatus = 7,
    EntityApprovStatus = 8,
    BillingStatus = 9,
    ActivityTab = 10,
    EStockStatus = 11,
    EStockCategory = 12,
    EBillingType = 13,
    ERentalStatus = 14,
    NotificationTableName = 15,
    StatusNotif = 16,
    PriorityNotif = 17,
    NotificationGroup = 18,
    IncidenceTab = 19,
    IncidenceGrade = 20,
    IncidenceState = 21,
    CrewSignStatus = 22,
    DriverStatusAssigned = 23,
    QuotationType = 25,
    TypeServiceCategory = 24,
    TypeTariff = 26,
    Period = 27,
    EServiceRentalStatus = 28,
    DateTypes = 29,
    EProviderSendStatus = 30,
    ProviderStateType = 31,
    DocumentStatus = 32,
    EComissionInvoiceStatus = 33,
}

export enum EHttpResponse {
    ok = 1,
    warning = 2,
    error = 3,
}

export enum StateType {
    Inactive = 0,
    Active = 1,
    Delete = 2,
    Cancelled = 3,
    Draft = 4,
    DocumentationPengingValidation = 5,
    DocumentationValidated = 6,
    Preinvoiced = 7,
    Invoiced = 8,
    InvoicedAmended = 9,
    NoInvoice = 10,
    ProviderCanceled = 11,
    Process = 12,
}

export enum ProviderStateType {
    NonAsigned = 0,
    Asigned = 1,
    Completed = 2,
    CompletedManual = 3,
    Valid = 4,
    AutoInvoice = 5,
    Incidence = 6,
    Refused = 7,
    Failed = 8,
    Cancelled = 9,
    CancelledProvider = 10,
}

export enum StateTypeFileTable {
    Active = StateType.Active,
    DocumentationPengingValidation = StateType.DocumentationPengingValidation,
    DocumentationValidated = StateType.DocumentationValidated,
}

export enum EntityExecStatus {
    Archived = 2,
    Active = 1,
    Draft = 0,
}

export enum EntityApprovStatus {
    Active = 0,
    Denied = 2,
    Approved = 1,
    Inactive = 3,
}

export enum EClasifTypeService {
    Hotel = 0,
    Taxi = 1,
    Other = 2,
    ItemPayment = 3,
    Rental = 4,
    Barge = 5,
}

export enum CrudFaluaActions {
    delete = 0,
    create = 1,
    reservation = 2,
}

export enum View {
    calendar = 0,
    data_table = 1,
    file_table = 2,
    file_manager = 3,
    selectable = 4,
}

export enum ProductionTab {
    none = 0,
    onsigners = 1,
    offsigners = 2,
    add_reserva = 3,
}

export enum ENotificationType {
    createReservation = 'CMANRSV',
    modSalePriceReservation = 'CPVENRSV',
    notTaxi = 'CTAXCREW',
    sheduleTaxi = 'CSHCTAX',
    notDocumentReservation = 'CDOCRSV',
    notDocumentQuotation = 'CDOCCTZ',
    notDocumentReservationQuotartion = 'CDOCCTZRSV',
    notTemplateCrew = 'CTEMCREW',
    expiredPassport = 'CEXPPASSCREW',
    customerReservation = 'CCUSRSV',
    modSalePriceGeneralTariff = 'CPVENGEN',
    modSalePriceTariffCustomer = 'CPVENCLI',
    addIntegratedQuotation = 'CINTCTZ',
    creditInvoiceReservation = 'CABOFACRSV',
    rejectedReservation = 'CBORFACRSV',
    faluaReservation = 'CFALBOR',
    injectedInvoicingReservation = 'CINVINJ',
    failedReservation = 'CNOSHORSV',
    cancelProviderReservation = 'CCANPRSV',
    modifyProviderReservation = 'CMODPRSV',
}

export enum ECrewQuotationListStatus {
    Unconfigured = 0,
    Configured = 1,
    AlreadyUsed = 2,
}

export enum EConceptType {
    Principal = 0,
    Agent = 1,
    Both = 2,
    None = 3,
}

export enum DriverStatusAssigned {
    notAssigned = 0,
    assigned = 1,
}

export enum TariffTab {
    default = 0,
    scale = 1,
    tags = 2,
}

export enum EQuotationType {
    Normal = 0,
    Integration = 1,
}

export enum EServiceOption {
    meetGreet = 'MG',
    recogida = 'REAER',
}

export enum ETypeServiceCode {
    meetGreet = 'MEET&GREET',
}

export enum ETransferDirection {
    OriginVoyage = 0,
    DestinationVoyage = 1,
}

export enum ECrewLetterStatus {
    Success = 0,
    Warning = 1,
    Failure = 2,
}

export enum StyleModal {
    OldBootstrap = 0,
    OilgasBootstrap = 1,
}

export enum CrewTypes {
    onsigner = 'OnSigner',
    offsigner = 'OffSigner',
    noCrew = 'NoCrew',
}

export enum DateFilter {
    start = 0,
    end = 1,
    request = 2,
    autoInvoiced = 3,
}

export enum ControlDomId {
    crewExcel = 'crewExcel',
    showVoyageGroups = 'showVoyageGroups',
    showReservations = 'showReservations',
    tabProject = 'tabProject',
    closetab = 'closeTab',
    gestion = 'Gestion',
    dropDownReservation = 'dropdownReserves',
    dropdownItemReservesList = 'dropdownItemReservesList',
    arrowCollapseProject = 'arrow_collapse_project',
    createReservation = 'createReservation',
}

export enum LocalStorageKey {
    activeGroup = 'activeGroup',
    datesActiveGroup = 'datesActiveGroup',
    filterReservation = 'FilterReservation',
    filterReservationConfig = 'FilterReservationConfig',
    createReservation = 'CreateReservation',
}

export enum MessageType {
    success = 0,
    warning = 1,
    error = 2,
    info = 3,
}
