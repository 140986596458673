import { Injectable } from '@angular/core';
import { REGEX } from 'app/constants/regex.constants';
import { PDF_EXTENSIONS, SPREADSHEET_EXTENSIONS, WORD_EXTENSIONS, IMAGE_EXTENSIONS } from 'app/constants/document.constant';
import { FileExtesionByteArray } from '../models/doc-download.model';
import { HttpHeaders } from '@angular/common/http';
import { AppGlobalDataService } from './app-global-data.service';
import { HTTP_REQUEST_CONSTANT } from 'app/constants/http-request.constant';
import { NotifyService } from 'app/services/notify/notify.service';
import { TranslateService } from '@ngx-translate/core';

declare var saveAs: any;

const base64abc = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '+',
    '/',
];

const base64codes = [
    255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
    255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 62, 255, 255, 255, 63, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 255, 255,
    255, 0, 255, 255, 255, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 255, 255, 255, 255, 255, 255,
    26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
];

@Injectable()
export class FileUtilService {
    constructor(private _appdata: AppGlobalDataService, private _notifyService: NotifyService, private _translateService: TranslateService) {}

    getBase64Code(charCode) {
        if (charCode >= base64codes.length) {
            throw new Error('Unable to parse base64 string.');
        }
        const code = base64codes[charCode];
        if (code === 255) {
            throw new Error('Unable to parse base64 string.');
        }
        return code;
    }

    bytesToBase64(bytes) {
        if (String(bytes).match(new RegExp(REGEX.BASE64))) {
            return bytes;
        }

        let result = '',
            i,
            l = bytes.length;
        for (i = 2; i < l; i += 3) {
            result += base64abc[bytes[i - 2] >> 2];
            result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
            result += base64abc[((bytes[i - 1] & 0x0f) << 2) | (bytes[i] >> 6)];
            result += base64abc[bytes[i] & 0x3f];
        }
        if (i === l + 1) {
            // 1 octet yet to write
            result += base64abc[bytes[i - 2] >> 2];
            result += base64abc[(bytes[i - 2] & 0x03) << 4];
            result += '==';
        }
        if (i === l) {
            // 2 octets yet to write
            result += base64abc[bytes[i - 2] >> 2];
            result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
            result += base64abc[(bytes[i - 1] & 0x0f) << 2];
            result += '=';
        }
        return result;
    }

    base64ToBytes(str) {
        if (String(str).match(new RegExp(REGEX.BASE64))) {
            if (str.length % 4 !== 0) {
                throw new Error('Unable to parse base64 string.');
            }
            const index = str.indexOf('=');
            if (index !== -1 && index < str.length - 2) {
                throw new Error('Unable to parse base64 string.');
            }
            let missingOctets = str.endsWith('==') ? 2 : str.endsWith('=') ? 1 : 0,
                n = str.length,
                result = new Uint8Array(3 * (n / 4)),
                buffer;
            for (let i = 0, j = 0; i < n; i += 4, j += 3) {
                buffer =
                    (this.getBase64Code(str.charCodeAt(i)) << 18) |
                    (this.getBase64Code(str.charCodeAt(i + 1)) << 12) |
                    (this.getBase64Code(str.charCodeAt(i + 2)) << 6) |
                    this.getBase64Code(str.charCodeAt(i + 3));
                result[j] = buffer >> 16;
                result[j + 1] = (buffer >> 8) & 0xff;
                result[j + 2] = buffer & 0xff;
            }
            return result.subarray(0, result.length - missingOctets);
        }

        return null;
    }

    base64encode(str, encoder = new TextEncoder()) {
        return this.bytesToBase64(encoder.encode(str));
    }

    base64decode(str, decoder = new TextDecoder()) {
        return decoder.decode(this.base64ToBytes(str));
    }

    /**
     * @description Gets the file type from the file name
     * @param  {string} fullFileName
     * @returns string
     */
    getTypeFileFromFullFileName(fullFileName: string): string {
        const EXTENSION: string = fullFileName.split('.')[fullFileName.split('.').length - 1];
        const ALLOWED_EXTENSION_LIST: FileExtesionByteArray[] = [
            ...PDF_EXTENSIONS,
            ...SPREADSHEET_EXTENSIONS,
            ...WORD_EXTENSIONS,
            ...IMAGE_EXTENSIONS,
        ];
        const ALLOWED_EXTENSION: FileExtesionByteArray = ALLOWED_EXTENSION_LIST.find((fileType) => fileType.extension === EXTENSION);

        if (ALLOWED_EXTENSION) {
            return ALLOWED_EXTENSION.type;
        }

        return '';
    }

    /**
     * @description Gets byteArray from base64
     * @param  {string} base64
     * @returns any
     */
    getUint8ArrayFromBase64(base64: string): any {
        const BINARY = atob(base64);
        const BINARY_LENGHT = BINARY.length;
        const BYTE_LIST = new Uint8Array(BINARY_LENGHT);
        for (let i = 0; i < BINARY_LENGHT; i++) {
            BYTE_LIST[i] = BINARY.charCodeAt(i);
        }
        return BYTE_LIST.buffer;
    }

    /**
     * @description Download a file from a Byte Array and names it
     * @param  {string} fileName
     * @param  {string | number[]} byte
     * @returns boolean - true: success, false: failed
     */
    downloadFileFromByteArrayAndName(fileName: string, byte: string | number[]): boolean {
        let blob = byte;

        if (typeof byte === 'string') {
            blob = this.getUint8ArrayFromBase64(byte);
        }

        try {
            saveAs(
                new Blob([blob as BlobPart], {
                    type: this.getTypeFileFromFullFileName(fileName),
                }),
                fileName
            );

            return true;
        } catch (error) {
            return false;
        }
    }

    /**
     *
     * @description add and get required http header for downloadable request response
     * @returns {*}  {{ headers: HttpHeaders; responseType: 'blob' }}
     */
    public getDownloadableHttpHeaders(): { headers: HttpHeaders; responseType: 'blob' } {
        const BLOB_RESPONSE_TYPE: 'blob' = 'blob';
        const OPTIONS = {
            headers: new HttpHeaders({
                [HTTP_REQUEST_CONSTANT.HEADER.APPLICATION_OCTET_STREAM.KEY]: HTTP_REQUEST_CONSTANT.HEADER.APPLICATION_OCTET_STREAM.VALUE,
                [HTTP_REQUEST_CONSTANT.HEADER.CONTENT_TYPE_JSON.KEY]: HTTP_REQUEST_CONSTANT.HEADER.CONTENT_TYPE_JSON.VALUE,
                [HTTP_REQUEST_CONSTANT.HEADER.AUTHORIZATION.KEY]: HTTP_REQUEST_CONSTANT.HEADER.AUTHORIZATION.VALUE + this._appdata.getToken(),
            }),
            responseType: BLOB_RESPONSE_TYPE,
        };
        return OPTIONS;
    }

    /**
     *
     * @description Download a file with HTML element
     * @param {string} fileName
     * @param {{ size: number; type: string }} response
     * @returns void
     */
    public DownloadHttpResponse(fileName: string, response: { size: number; type: string }): void {
        if (!fileName.length || !this.ValidateDownloadHttpResponse(response)) {
            return;
        }

        const DOWNLOADABLE_TAG_ELEMENT = document.createElement(HTTP_REQUEST_CONSTANT.DOWNLOADABLE_ELEMENT_TAG) as HTMLAnchorElement;
        document.body.appendChild(DOWNLOADABLE_TAG_ELEMENT);

        const BLOB: Blob = new Blob([response as Blob], { type: HTTP_REQUEST_CONSTANT.OCTET_STREAM_RESPONSE_TYPE });
        const URL = window.URL.createObjectURL(BLOB);

        DOWNLOADABLE_TAG_ELEMENT.href = URL;
        DOWNLOADABLE_TAG_ELEMENT.download = fileName;
        DOWNLOADABLE_TAG_ELEMENT.click();

        window.URL.revokeObjectURL(URL);
    }

    /**
     *
     * @private
     * @description Handler successfully download file
     * @param {string} fileName
     * @param {number} toastId
     * @returns () => void
     */
    public handleDownloadResult(fileName: string, toastId: number): () => void {
        return () => {
            this._notifyService.downloadFileMessageSuccesfully(fileName, toastId);
        };
    }

    /**
     *
     * @private
     * @description Handler error download file
     * @param {string} [errorMessage='ARCHIVO.DOWNLOADS.DOWNLOADED_ERROR']
     * @param {number} toastId
     * @returns (error) => void
     */
    public handleDownloadError(toastId: number, errorMessage = 'ARCHIVO.DOWNLOADS.DOWNLOADED_ERROR'): (error) => void {
        return (error) => {
            this._notifyService.downloadFileMessageError(this._translateService.instant(errorMessage), toastId);
        };
    }

    /**
     *
     * @private
     * @description Validate download http response
     * @param {{ size: number,  type: string}} result
     * @returns boolean
     */
    private ValidateDownloadHttpResponse(result: { size: number; type: string }): boolean {
        const IS_OCTET_STREAM_RESPONSE_TYPE: boolean = result.type !== HTTP_REQUEST_CONSTANT.OCTET_STREAM_RESPONSE_TYPE;
        return !result || !result.size || IS_OCTET_STREAM_RESPONSE_TYPE;
    }
}
