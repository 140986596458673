import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import { CookieKeys } from 'app/shared/mix/enum';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    private onSubject = new Subject<{ key: string; value: any }>();
    public changes = this.onSubject.asObservable().pipe(share());

    constructor() {
        this.start();
    }

    ngOnDestroy() {
        this.stop();
    }

    public getStorage() {
        let s = [];
        for (let i = 0; i < localStorage.length; i++) {
            s.push({
                key: localStorage.key(i),
                value: JSON.parse(localStorage.getItem(localStorage.key(i))),
            });
        }
        return s;
    }

    public store(key: string, data: any): void {
        localStorage.setItem(key, JSON.stringify(data));
        // the local application doesn't seem to catch changes to localStorage...
        this.onSubject.next({ key: key, value: data });
    }

    public clear(key) {
        localStorage.removeItem(key);
        // the local application doesn't seem to catch changes to localStorage...
        this.onSubject.next({ key: key, value: null });
    }

    private start(): void {
        window.addEventListener('storage', this.storageEventListener.bind(this));
    }

    private storageEventListener(event: StorageEvent) {
        if (event.storageArea == localStorage) {
            let v;
            try {
                v = JSON.parse(event.newValue);
            } catch (e) {
                v = event.newValue;
            }
            this.onSubject.next({ key: event.key, value: v });
        }
    }

    private stop(): void {
        window.removeEventListener('storage', this.storageEventListener.bind(this));
        this.onSubject.complete();
    }

    /**
     * @description Get Item from localStorage
     * @param  {string} key
     * @returns any | null
     */
    getItem(key: string): any | null {
        const ITEM: any = localStorage.getItem(key);

        if (!ITEM) {
            return null;
        }

        return ITEM;
    }

    /**
     * @description Set Item to localStorage
     * @param  {string} key
     * @param  {any} value
     * @returns void
     */
    setItem(key: string, value: any): void {
        localStorage.setItem(key, value);
    }

    /**
     * @description Get Show What's new from localStorage
     * @returns boolean
     */
    getShowWhatsNew(): boolean {
        const SHOW_WHATS_NEW: string = this.getItem(CookieKeys.show_whats_new);

        if (!SHOW_WHATS_NEW || SHOW_WHATS_NEW === undefined || SHOW_WHATS_NEW === '') {
            return false;
        }

        return SHOW_WHATS_NEW === 'true';
    }

    /**
     * @description Set Show What's new on localStorage
     * @param  {boolean} value
     * @returns void
     */
    setShowWhatsNew(value: boolean): void {
        localStorage.setItem(CookieKeys.show_whats_new, JSON.stringify(value));
    }
}
