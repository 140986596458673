import { NgModule, SystemJsNgModuleLoader, LOCALE_ID } from '@angular/core';
import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthWeb } from './shared/services/auth/auth.web';
import { AppGlobalDataService } from './shared/services/app-global-data.service';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { provideRoutes } from '@angular/router';
import { GuardService } from './shared/services/auth/guard.service';
import { SidebarService } from './shared/components/menu/sidebar.service';
import { registerLocaleData } from '@angular/common';
import { HttpModule } from '@angular/http';
import localeEs from '@angular/common/locales/es';
import { CookiesModule } from './shared/components/cookies/cookies.module';
import { GeneralTypeService } from './services/general-type/general-type.service';
import { OldReservationService } from './gestion/reservation/reservation.service';
import { AutoInvoiceService } from './gestion/auto-invoice/services/auto-invoice.service';
import { NumericDirective } from './shared/directives/numeric.directive';
import { FileUtilService } from './shared/services/file-util.service';
import { HttpBaseInterceptor } from './core/auth/interceptors/http-base.interceptor.service';

import { ToastrModule } from 'ngx-toastr';
import { LoginService } from './login/login.service';
import { ReloadAppModule } from './shared/components/reload-app/reload-app.module';
import { WhatsNewModule } from './shared/modules/whats-new/whats-new.module';
import { BusinessAuditService } from './services/business-audit/business-audit.service';
import { DebounceClickDirective } from './shared/directives/debounce-click.directive';
import { TimeoutInterceptor, DEFAULT_TIMEOUT } from './core/auth/interceptors/http-timeout.interceptor.service';

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}
let expire = new Date();
let exp = expire.setDate(expire.getDate() + 5);

registerLocaleData(localeEs, 'es');

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AppRoutingModule,
        CookiesModule,
        ReloadAppModule,
        WhatsNewModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            closeButton: true,
            preventDuplicates: true,
        }),
    ],
    declarations: [AppComponent, NumericDirective, DebounceClickDirective],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: APP_BASE_HREF, useValue: '/' },
        { provide: Window, useValue: window },
        { provide: LOCALE_ID, useValue: 'es-Ar' },
        SystemJsNgModuleLoader,
        provideRoutes([
            { loadChildren: 'app/gestion/provider/provider.module#ProviderModule', path: '' },
            { loadChildren: 'app/gestion/project/project.module#ProjectModule', path: '' },
            { loadChildren: 'app/gestion/person/person.module#PersonModule', path: '' },
            { loadChildren: 'app/gestion/vessel/vessel.module#VesselModule', path: '' },
            { loadChildren: 'app/gestion/typeservice/typeservice.module#TypeServiceModule', path: '' },
            { loadChildren: 'app/gestion/reservation/reservation.module#ReservationModule', path: '' },
            { loadChildren: 'app/gestion/quotation/quotation.module#QuotationModule', path: '' },
            { loadChildren: 'app/gestion/pricelist/pricelist.module#PriceListModule', path: '' },
            { loadChildren: 'app/gestion/flight/flight.module#FlightModule', path: '' },
            { loadChildren: 'app/gestion/activity/activity.module#ActivityModule', path: '' },
            { loadChildren: 'app/gestion/notification/notification.module#NotificationModule', path: '' },
            { loadChildren: 'app/gestion/rental/rental.module#RentalModule', path: '' },
            { loadChildren: 'app/gestion/inventory/inventory.module#InventoryModule', path: '' },
            { loadChildren: 'app/gestion/incidence/incidence.module#IncidenceModule', path: '' },
            { loadChildren: 'app/gestion/address/address.module#AddressModule', path: '' },
            { loadChildren: 'app/gestion/service/service.module#ServiceModule', path: '' },
            { loadChildren: 'app/gestion/typedocument/typedocument.module#TypeDocumentModule', path: '' },
            { loadChildren: 'app/gestion/voyage/voyage.module#VoyageModule', path: '' },
            { loadChildren: 'app/gestion/crew/crew.module#CrewModule', path: '' },
            { loadChildren: 'app/gestion/group/group.module#GroupModule', path: '' },
            { loadChildren: 'app/gestion/official-organization/official-organization.module#OfficialOrganizationModule', path: '' },
            { loadChildren: 'app/gestion/invoicing/invoicing.module#InvoicingModule', path: '' },
            { loadChildren: 'app/gestion/base-rate/base-rate.module#BaseRateModule', path: '' },
            { loadChildren: 'app/gestion/auto-invoice/auto-invoice.module#AutoInvoiceModule', path: '' },
            { loadChildren: 'app/gestion/crew-management/crew-management.module#CrewManagementModule', path: '' },
        ]),
        AppGlobalDataService,
        AuthWeb,
        HttpClient,
        GuardService,
        SidebarService,
        GeneralTypeService,
        OldReservationService,
        AutoInvoiceService,
        TranslateService,
        FileUtilService,
        LoginService,
        BusinessAuditService,
        { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
        { provide: DEFAULT_TIMEOUT, useValue: 180000 },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpBaseInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
