import { throwError as observableThrowError, Observable, Observer, Subject } from 'rxjs';

import { catchError, map, take, takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';

import { AppGlobalDataService } from '../shared/services/app-global-data.service';
import { DBOperation, CookieKeys } from '../shared/mix/enum';
import { Global } from '../shared/mix/global';
import { DataUserLogin } from './login.interfaz';
import { IValidatedUser } from '../interface/user';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'app/core/services/local-storage/local-storage.service';
import { DivisionService } from 'app/core/http-services/division/division.service';
import { FIXED_VALUE_DIVISION_CONSTANTS } from 'app/constants/fixedValueDivision.constants';

@Injectable()
export class LoginService implements OnDestroy {
    private destroy$ = new Subject<void>();

    constructor(
        private _httpClient: HttpClient,
        private _appdata: AppGlobalDataService,
        private _localStorageService: LocalStorageService,
        private _divisionService: DivisionService
    ) {}

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    get(uri: string): Observable<any> {
        let options = this._appdata.getDefaultRequestOptionsHttpClient(DBOperation.select);
        return this._httpClient.get(this._appdata.getSafeEndpoint(uri), options).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((response: any) => <any>this.handleError(response))
        );
    }

    post(url: string, model: any): Observable<any> {
        return this._httpClient.post(url, model).pipe(
            map((response: Response) => response),
            catchError((response: Response) => <any>this.handleError(response))
        );
    }

    private handleError(error: Response) {
        return observableThrowError(this._appdata.getMessageErrorHttpClient(error));
    }

    login(data_log: DataUserLogin): Observable<any> {
        let o = new Observable((observer: Observer<any>) => {
            this.post(Global.BASE_API_URL_USER_VALIDATION, data_log).subscribe(
                (result_validation) => {
                    if (result_validation.length > 35) {
                        //el token tiene más de 35 caracteres

                        if (!data_log.SavePass) data_log.Password = '';

                        this._appdata.putClientStorage(CookieKeys.login, data_log, { hours: 360 }, true);

                        this._appdata.setToken(result_validation); //establecemos token de validacion

                        this._appdata.deleteClientStorage(CookieKeys.permisissions); //reset cookie permisos

                        this._localStorageService.setShowWhatsNew(true);

                        //cogemos datos del usuario,
                        this.get(Global.BASE_API_URL_LOGGED_USER).subscribe(
                            (user_data: IValidatedUser) => {
                                if (user_data.GroupCode !== Global.CHOFER_USER_GROUP) {
                                    const user: IValidatedUser = {
                                        Id: user_data.Id,
                                        Domain: user_data.Domain,
                                        UserName: user_data.UserName,
                                        FirstName: user_data.FirstName,
                                        CompleteName: user_data.CompleteName,
                                        Email: user_data.Email,
                                        GroupCode: user_data.GroupCode,
                                        ActiveLanguage: user_data.ActiveLanguage,
                                        ActiveDivision: user_data.ActiveDivision,
                                    };

                                    this._appdata.setInfoLoggedUser(user);
                                    this._appdata.jusLogin(true);
                                    observer.next(true);
                                    this._appdata.setCurrency(user_data.ActiveDivision.CurrencyId);
                                } else {
                                    this._appdata.deleteClientStorage(CookieKeys.login);
                                    this._appdata.deleteClientStorage(CookieKeys.token);
                                    observer.next('Usuario taxista, login disponible en la App móvil');
                                }
                            },
                            (error) => {
                                this._appdata.jusLogin(false);
                                observer.next(error);
                            },
                            () => {
                                observer.complete();
                            }
                        );

                        this._divisionService
                            .getFixedValuesDivisionListByFixedValueCodeList([FIXED_VALUE_DIVISION_CONSTANTS.CSGSHOWPO])
                            .pipe(take(1), takeUntil(this.destroy$))
                            .subscribe();
                    } else {
                        //cuando la contraseña no es correcta, el API deveulve string vacio
                        this._appdata.jusLogin(false);
                        observer.next('USERS.LOGIN.ERROR');
                        observer.complete();
                    }
                },
                (error) => {
                    this._appdata.jusLogin(false);
                    observer.next(error);
                    observer.complete();
                }
            );
        });
        return o;
    }
}
