import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { ANALYTICS_TAGS } from '../../constants/analytics-tags';
import { environment } from '../../../environments/environment';
import { AnalyticsEvent } from './anlytics.interface';
import { AppGlobalDataService } from '../../shared/services/app-global-data.service';

declare let gtag: Function;

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    constructor(private _appdata: AppGlobalDataService) {}

    public eventTracking(analyticsEvent: AnalyticsEvent) {
        gtag('event', analyticsEvent.eventName, {
            eventCategory: analyticsEvent.eventCategory,
            eventLabel: analyticsEvent.eventLabel,
            eventAction: this._appdata.isset(analyticsEvent.eventAction) ? analyticsEvent.eventAction : null,
            eventValue: this._appdata.isset(analyticsEvent.eventValue) ? analyticsEvent.eventValue : null,
        });
    }

    public routeTracking(event: NavigationEnd) {
        if (environment.tagAnalytics) {
            gtag(ANALYTICS_TAGS.config, environment.tagAnalytics, {
                [ANALYTICS_TAGS.page_path]: event.urlAfterRedirects,
            });
        }
    }
}
