import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IReservationAutoInvoice } from '../../../Interface/auto-invoice';
import { MyNotification } from '../../../shared/components/notificacion/notification.class';
import { Global } from '../../../shared/mix/global';
import { AppGlobalDataService } from '../../../shared/services/app-global-data.service';
import { OldReservationService } from '../../reservation/reservation.service';

@Injectable({
    providedIn: 'root',
})
export class AutoInvoiceService {
    loading: boolean = false;
    notification: MyNotification = new MyNotification();

    constructor(
        private _reservationService: OldReservationService,
        private _appdata: AppGlobalDataService,
        private translateService: TranslateService
    ) {}

    generateAutoInvoice(payload: any, fixedNotification: MyNotification): Observable<IReservationAutoInvoice[]> {
        this.loading = true;
        return this._reservationService.post(Global.BASE_RESERVA_AUTOINVOICE + 'GenerateAutoInvoice', payload).pipe(
            map((response: IReservationAutoInvoice[]) => {
                this.loading = false;
                // fixedNotification.setcloseDelay(0);
                return response;
            })
        );
    }

    generateAutoAmmended(payload: any, fixedNotification: MyNotification): Observable<IReservationAutoInvoice[]> {
        this.loading = true;
        return this._reservationService.post(Global.BASE_RESERVA_AUTOINVOICE + 'GenerateAutoAmmend', payload).pipe(
            map((response: IReservationAutoInvoice[]) => {
                this.loading = false;
                // fixedNotification.setcloseDelay(0);
                return response;
            })
        );
    }
}
