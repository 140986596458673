import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

setWindowVariables();
if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));

// platformBrowserDynamic()
//     .bootstrapModule(AppModule)
//     .then(() => {
//         if ('serviceWorker' in navigator && environment.versionControl) {
//             navigator.serviceWorker.register('ngsw-worker.js');
//         }
//     })
//     .catch((err) => console.log(err));

function setWindowVariables() {
    (window as any).backendApi = environment.backendApi;
    (window as any).documentationApi = environment.documentationApi;
    (window as any).trampWeb = environment.trampWeb;
    (window as any).driverVHost = environment.driverVHost;
}
