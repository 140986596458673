import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DBOperation } from '../../shared/mix/enum';
import { Global } from '../../shared/mix/global';
import { AppGlobalDataService } from '../../shared/services/app-global-data.service';

@Injectable({
    providedIn: 'root',
})
export class GeneralTypeService {
    constructor(private _http: HttpClient, private _appdata: AppGlobalDataService) {}

    getForTableName(tableName: string): Observable<any> {
        const urlendpoint = this._appdata.getSafeEndpoint(Global.BASE_GENERAL_TIPOS_ENDPOINT + 'GetAllByTableName?tableName=' + tableName);
        const options = this._appdata.getDefaultRequestOptionsHttpClient(DBOperation.post_query);
        return this._http.post(urlendpoint, null, options).pipe(
            map((response: any) => {
                return this._appdata.getResponseHttpClient(response);
            }),
            catchError((response: any) => this.handleError(response))
        );
    }

    private handleError(error: any): any {
        return observableThrowError(this._appdata.getMessageErrorHttpClient(error));
    }
}
