import { UtilHelper } from './util.model';

export class FixedValueDivision {
    constructor(public id?: number, public code?: string, public description?: string) {}
}

export class FixedValueDivisionHelper {
    static mapToObject(result: any): FixedValueDivision {
        return new FixedValueDivision(result.Id, result.Code, result.Description);
    }

    static mapToObjectList(result: any[]): FixedValueDivision[] {
        return UtilHelper.mapToObjectList<FixedValueDivision>(result, this.mapToObject.bind(this));
    }
}
