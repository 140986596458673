export enum ModalMessageType {
    error = 0,
    warning = 1,
    success = 2,
    info = 3,
}

export enum ModalMessageSize {
    small = 'sm',
    large = 'lg',
    extraLarge = 'xl',
    medium = 'md',
}
