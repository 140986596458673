import { FileExtesionByteArray } from 'app/shared/models/doc-download.model';

export const PDF_EXTENSIONS: FileExtesionByteArray[] = [
    {
        extension: 'pdf',
        type: 'application/pdf',
    },
];

export const SPREADSHEET_EXTENSIONS: FileExtesionByteArray[] = [
    {
        extension: 'xls',
        type: 'application/vnd.ms-excel',
    },
    {
        extension: 'xlsx',
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
];

export const WORD_EXTENSIONS: FileExtesionByteArray[] = [
    {
        extension: 'doc',
        type: 'application/msword',
    },
    {
        extension: 'docx',
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    },
];

export const IMAGE_EXTENSIONS: FileExtesionByteArray[] = [
    {
        extension: 'jpeg',
        type: 'image/jpeg',
    },
    {
        extension: 'jpg',
        type: 'image/jpg',
    },
    {
        extension: 'png',
        type: 'image/png',
    },
];

export const BYTE_ARRAY_HEADER = {
    PDF: 'data:application/pdf;base64,',
};

export const DOCUMENT_ID_PREFIX = 'ID';
