export enum EDATE_FORMAT {
    SHORT = 'dd/MM/yyyy',
    SHORT_WITH_HOUR = 'DD/MM/yyyy HH:mm',
    ONLY_HOUR = 'HH:mm',
    DATABASE = 'YYYY-MM-DD',
    DATABASE_WITH_TIME = 'YYYY-MM-DD[T]HH:mm:ss',
    PARTIAL_DATABASE_WITH_TIME_ZERO_HOURS = 'T00:00:00',
    SHORT_TEMPLATE = 'dd/MM/yyyy',
    SHORT_SHORT_WITH_HOUR_TEMPLATE = 'dd/MM/yyyy HH:mm',
}

export const CHANGE_DATE_FORMAT_LIST = ['DD/MM/YYYY', 'D/MM/YYYY', 'D/MM/YY', 'DD/M/YYYY', 'DD/M/YY', 'D/M/YYYY', 'D/M/YY'];

export const CHANGE_DATE_WITH_HOURS_FORMAT_LIST = [
    'DD/MM/YYYY HH:mm',
    'DD/MM/YYYY H:mm',
    'D/MM/YYYY HH:mm',
    'D/MM/YYYY H:mm',
    'DD/M/YYYY HH:mm',
    'DD/M/YYYY H:mm',
    'D/M/YYYY HH:mm',
    'D/M/YYYY H:mm',
    'DD/MM/YY HH:mm',
    'DD/MM/YY H:mm',
    'D/MM/YY HH:mm',
    'D/MM/YY H:mm',
    'DD/M/YY HH:mm',
    'DD/M/YY H:mm',
    'D/M/YY HH:mm',
    'D/M/YY H:mm',
];
