import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../login/login.service';
import { AppGlobalDataService } from '../../services/app-global-data.service';
@Component({
    selector: 'app-cookies',
    templateUrl: './cookies.component.html',
    styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent implements OnInit {
    cookies = {
        technical: true,
        personalization: false,
        analytics: false,
        advertising: false,
    };

    url = 'https://tramp.romeushipping.com';

    constructor(private _appdata: AppGlobalDataService, private router: Router) {}

    ngOnInit(): void {
        this.resetWievs();

        //this.getHeight();
        this.getCookies();

        this.checkAcceptCookies();

        document.getElementById('personalization-cookie')['checked'] = false;
        document.getElementById('analytics-cookie')['checked'] = false;
        document.getElementById('advertising-cookie')['checked'] = false;
    }

    @HostListener('click', ['$event'])
    addEventListener(ev: any): void {
        if (ev.target.classList.contains('prevent')) {
            ev.preventDefault();
            document.querySelector(ev.srcElement.hash).scrollIntoView();
        }
    }

    resetWievs(): void {
        document.getElementById('legal-warning').style.display = 'none';
        document.getElementById('privacy-policy').style.display = 'none';
        document.getElementById('cookies-policy').style.display = 'none';
        document.getElementById('alert-cookies').style.display = 'none';
    }

    getHeight(): void {
        let doc = document.getElementsByTagName('body');
        let x = document.getElementsByClassName('mod-scroll');
        const height = window.innerHeight - 250;
        doc[0].style.minHeight = window.innerHeight + 'px';
        for (let i = 0; i < x.length; i++) {
            x[i]['style'].height = height + 'px';
        }
    }

    navigateToView(view: string | null): void {
        this.resetWievs();
        if (view) {
            const element = document.getElementById(view);
            element.style.display = 'block';
        }
    }

    closeModal(): void {
        if (!this.existAcceptCookie()) {
            this.navigateToView('cookies-policy');
            document.querySelector('#config-cookies').scrollIntoView();
        } else {
            this.navigateToView(null);
        }
    }

    checkAcceptCookies(): void {
        if (!this.existAcceptCookie()) {
            this._appdata.logoutUser();
            this.navigateToView('alert-cookies');
        }
    }

    acceptCookies(): void {
        this.setCookies();
        if (this.existAcceptCookie()) {
            this.navigateToView(null);
        }
    }

    existAcceptCookie(): boolean {
        return this._appdata.getCookie('ac_cookies') !== null;
    }

    setCookies(): void {
        this.cookies = {
            technical: document.getElementById('technical-cookie')['checked'],
            personalization: document.getElementById('personalization-cookie')['checked'],
            analytics: document.getElementById('analytics-cookie')['checked'],
            advertising: document.getElementById('advertising-cookie')['checked'],
        };

        let expire = new Date();
        expire.setDate(expire.getDate() + 365);

        this._appdata.setCookie('ac_cookies', JSON.stringify(this.cookies), expire.toUTCString());
    }

    getCookies(): void {
        const ac_cookies: any = JSON.parse(this._appdata.getCookie('ac_cookies'));

        if (this.existAcceptCookie()) {
            document.getElementById('technical-cookie')['checked'] = ac_cookies['technical'];
            document.getElementById('personalization-cookie')['checked'] = ac_cookies['personalization'];
            document.getElementById('analytics-cookie')['checked'] = ac_cookies['analytics'];
            document.getElementById('advertising-cookie')['checked'] = ac_cookies['advertising'];
        } else {
            this.navigateToView('legal-warning');
        }
    }
}
