import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { AppGlobalDataService } from 'app/shared/services/app-global-data.service';
import { LocalStorageService } from 'app/core/services/local-storage/local-storage.service';

@Component({
    selector: 'tramp-whats-new-button',
    templateUrl: './whats-new-button.component.html',
    styleUrls: ['./whats-new-button.component.scss'],
})
export class WhatsNewButtonComponent implements OnInit {
    constructor(public _appdata: AppGlobalDataService, public _localStorageService: LocalStorageService) {}

    ngOnInit(): void {}

    /**
     * @description Open whats new web
     * @returns void
     */
    openWhatsNewWeb(): void {
        window.open(environment.whatsNewWeb, '_blank');
        this._localStorageService.setShowWhatsNew(false);
    }
}
