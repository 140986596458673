import { Component, OnInit } from '@angular/core';
import { CheckForUpdateService } from 'app/services/check-version/check-for-update.service';

@Component({
    selector: 'app-reload-app',
    templateUrl: './reload-app.component.html',
    styleUrls: ['./reload-app.component.scss'],
})
export class ReloadAppComponent implements OnInit {
    constructor(public _checkVersionService: CheckForUpdateService) {}

    ngOnInit() {}
}
