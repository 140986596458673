﻿export enum ActivityTab {
    Project = 'csgpry',
    Person = 'aetper',
    Pricelist = 'csgpre',
    Crew = 'csgprybuqtri',
    GenericTariff = 'csgprosergentar',
    CustomerTariff = 'csgclisertar',
    Voyage = 'csgbuqvia',
    Reservation = 'csgrsv',
    Quotation = 'csgctz',
    Provider = 'aetentcsg',
    CrewLetter = 'ghostcrewletter',
}
export enum ActivityCode {
    insert = 'INS',
    modify = 'MOD',
    delete = 'DEL',
}
