export const environment = {
    production: true,
    development: false,
    versionControl: true,
    syncfusionApi: 'https://auth-api.romeushipping.com',
    backendApi: 'https://shippy-api.romeushipping.com',
    trampWeb: 'https://tramp.romeushipping.com/',
    documentationApi: 'https://docs.grm-e.com',
    InstrumentationKey: '97563b79-c603-46d9-a110-7de207e6974e',
    driverVHost: 'https://pickup',
    shippingWeb: 'https://shippy.romeushipping.com',
    whatsNewWeb: 'https://grm-pro-whatsnew-web.azurewebsites.net/home/whats-new/TrampSys',
    enabledLogging: true,
    tagAnalytics: 'G-9CT5FKN7MY',
    environmentApi: 'https://shippy-api.romeushipping.com/api/enviroment',
    businessAudit: false,
};
