import { Component } from '@angular/core';
import { routerTransitionAnimation } from '../router.animations';

@Component({
    // moduleId: module.id.replace('built', 'app'),
    selector: 'app-not-found',
    styleUrls: ['./not-found.component.css'],
    templateUrl: './not-found.component.html',
    animations: [routerTransitionAnimation],
})
export class NotFoundComponent {}
