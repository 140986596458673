import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';

import { ROUTES_DEFINITION } from '../../../constants/router-definitions';

import { catchError, tap } from 'rxjs/operators';
import { AppGlobalDataService } from '../../../shared/services/app-global-data.service';
import { NotifyService } from '../../../../app/services/notify/notify.service';
import { APP } from 'app/constants/app-global.constants';
import { TranslateService } from '@ngx-translate/core';
import { GlobalErrorHandlerService } from 'app/services/global-error-handler/global-error-handler.service';
import { Global } from 'app/shared/mix/global';
import { MessageType } from 'app/shared/mix/enum';
import { environment } from 'environments/environment';

declare var controlSizeMemory: any;

@Injectable({
    providedIn: 'root',
})
export class HttpBaseInterceptor implements HttpInterceptor {
    constructor(
        private _appdata: AppGlobalDataService,
        private router: Router,
        private _notifyService: NotifyService,
        private _translateService: TranslateService,
        private _globalErrorHandlerService: GlobalErrorHandlerService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.stopRequestForErrorSize(req)) {
            return throwError(this._translateService.instant('ARCHIVO.EXCEEDED_MAXIMUN_FILE_SIZE_CONTACT_SUPPORT'));
        }

        let token: string = this._appdata.getToken();
        let request = req;

        // analize params from Url and set cookie used on tramp ifranes
        if (token === null || undefined) {
            const url = window.location.href.split('?')[0];
            const queryString: string = '?' + window.location.href.split('?')[1];
            const urlParams = new URLSearchParams(queryString);

            if (urlParams.get('token') && urlParams.get('client') && urlParams.get('client') === 'tramping') {
                token = urlParams.get('token');
            }
        }

        request = req.clone({
            setHeaders: {
                authorization: `Bearer ${token}`,
            },
        });

        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                const savedexceptionsMessageList: string[] = this._appdata.getExceptionMessageList();
                if (event instanceof HttpResponse && event.url.indexOf(Global.SendException) === -1) {
                    this._globalErrorHandlerService.sendExceptionList(savedexceptionsMessageList);
                }

                if (event instanceof HttpResponse) {
                    if (
                        this._appdata.isset(event) &&
                        event.status === 200 &&
                        this._appdata.isset(event.body) &&
                        this._appdata.isset(event.body.Messages) &&
                        this._appdata.isset(event.body.Messages[0]) &&
                        this._appdata.isset(event.body.Messages[0].MessageType) &&
                        this._appdata.isset(event.body.Messages[0].Message) &&
                        event.body.Messages[0].MessageType === MessageType.error
                    ) {
                        this._notifyService.falloMessage(event.body.Messages[0].Message);
                    }
                }
            }),
            catchError((err: HttpErrorResponse) => {
                if (!err.status && err.url.includes(environment.backendApi + '/api/')) {
                    const errorMessage: string = `${err.message} ${this._appdata.formatDateWithMoment(new Date(), 'DD/MM/YYYY HH:mm')}`;

                    this._appdata.addExceptionMessageToLocalStorage(errorMessage);
                }

                if ([401, 0].includes(err.status)) {
                    localStorage.clear();
                    this.router.navigate([ROUTES_DEFINITION.LOGIN]);

                    if (this.router.url === ROUTES_DEFINITION.BASE) {
                        window.location.reload();
                    }
                }

                if (err.status === 500) {
                    const error = JSON.parse(JSON.stringify(err.error));
                    if (error.hasOwnProperty('UserMessages')) {
                        const userMessageList: { Message: string }[] = JSON.parse(JSON.stringify(err.error)).UserMessages;
                        if (userMessageList.length > 0) {
                            return throwError(userMessageList[0].Message);
                        }
                    }

                    if (err.hasOwnProperty('statusText')) {
                        if (err.statusText.length > 0) {
                            return throwError(err.statusText);
                        }
                    }

                    this._notifyService.falloMessage('Internal Server Error');
                }

                return throwError(err);
            })
        );
    }

    stopRequestForErrorSize(req: HttpRequest<any>): boolean {
        if (req && req.body !== null) {
            return controlSizeMemory(req.body) > APP.MAX_SIZE_REQUEST_OBJECT;
        }

        return false;
    }
}
