import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppGlobalDataService } from '../app-global-data.service';

@Injectable()
export class AuthWeb {
    constructor(private router: Router, private _appdata: AppGlobalDataService) {}

    resolve(): void {
        const token = this._appdata.getToken();
        if (token) {
            this.router.navigate(['/']);
        }
    }

    checkIsLogged(): boolean {
        const token = this._appdata.getToken();
        if (!token) {
            return false;
        }
        return true;
    }
}
