﻿export enum TableNames {
    Booking = 'csgbkg',
    BillOfLanding = 'csgbol',
    Rental = 'csgalqinv',
    Container = 'aebctn',
    TrampQuotation = 'csgctz',
    Documentation = 'almgesfic',
    Reservation = 'csgrsv',
    VesselVoyage = 'csgbuqvia',
    PriceList = 'csgpre',
    Crew = 'csgprybuqtri',
    MeetGreet = 'seropc',
    ContainerLifeCycleHeader = 'csgtipcabmov',
    ContainerLifeCycleMovement = 'csgmov',
    ShippyQuotation = 'csgofeven',
    PdaGroup = 'csgpdal',
    Project = 'csgpry',
    Person = 'aetper',
    GenericTariff = 'csgprosergentar',
    CustomerTariff = 'csgclisertar',
    Incidence = 'alminc',
    Stock = 'csgstk',
    AutoInvoice = 'ltctrnedi',
    AutomaticMailInvoice = 'csgrobges',
}

export const LAST_TABLE_ID = 999999999;
