import { NgModule } from '@angular/core';
import { WhatsNewButtonComponent } from './components/whats-new-button.component';
import { CommonModule } from '@angular/common';

const MODULES = [CommonModule];

@NgModule({
    declarations: [WhatsNewButtonComponent],
    imports: [MODULES],
    exports: [WhatsNewButtonComponent],
})
export class WhatsNewModule {}
