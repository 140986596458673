import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthWeb } from './shared/services/auth/auth.web';
import { NotFoundComponent } from './not-found/not-found.component';
import { Global } from './shared/mix/global';
import { ROUTES_DEFINITION } from './constants/router-definitions';
import { GuardService } from './shared/services/auth/guard.service';

const routes: Routes = [
    {
        path: ROUTES_DEFINITION.LOGIN,
        loadChildren: 'app/login/login.module#LoginModule',
        resolve: [AuthWeb],
    },
    {
        path: Global.ADMIN_ZONE_URI,
        loadChildren: 'app/layout/layout.module#LayoutModule',
        canActivate: [GuardService],
    },
    { path: '404', component: NotFoundComponent },
    { path: '**', redirectTo: '/404' },
];
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    declarations: [NotFoundComponent],
})
export class AppRoutingModule {
    constructor() {}
}
