﻿import { NotifyService } from '../../../services/notify/notify.service';
import { INotificacion } from '../../../interface/shared';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

export class MyNotification {
    notifyService: NotifyService;

    msg: INotificacion = {
        delayClose: 3600,
        clases: 'alert alert-success alert-dismissible',
        titulo: 'Usuario',
        icon: 'fa fa-info-circle',
        descripcion: '',
    };

    constructor(delay?: number) {
        this.resetMessage();
        this.msg.delayClose = delay != null ? delay : this.msg.delayClose;
    }

    setcloseDelay(miliseconds: number) {
        this.msg.delayClose = miliseconds;
    }

    //MENSAJE NOTIFICACIONES
    exitoMessage(message: string, delay?: number) {
        message = typeof message !== 'string' ? JSON.stringify(message) : message;
        this.msg.clases = 'alert btn-ok alert-dismissible';
        this.msg.icon = 'fa fa-check';
        this.msg.descripcion = message;
        this.msg.delayClose = 3600;
    }

    falloMessage(message: any) {
        this.resetMessage();
        message = typeof message !== 'string' ? JSON.stringify(message) : message;
        this.msg.clases = 'alert alert-danger alert-dismissible';
        this.msg.icon = 'fa fa-exclamation';
        this.msg.descripcion = message;
        this.msg.delayClose = 7200;
    }

    infoMessage(message: string) {
        message = typeof message !== 'string' ? JSON.stringify(message) : message;
        this.msg.clases = 'alert alert-info alert-dismissible';
        this.msg.icon = 'fa-info';
        this.msg.descripcion = message;
        this.msg.delayClose = 7200;
    }

    downloadMessage(message: string): void {
        message = typeof message !== 'string' ? JSON.stringify(message) : message;
        this.msg.clases = 'alert alert-dismissible font-weight-bold';
        this.msg.icon = 'fa fa-download';
        this.msg.descripcion = message;
        this.msg.delayClose = 3600;
    }

    messageDisplayed() {
        if (this.msg.delayClose > 0) {
            setTimeout(() => {
                this.resetMessage();
            }, this.msg.delayClose);
        }
    }

    resetMessage() {
        this.msg.clases = 'alert alert-info alert-dismissible';
        this.msg.icon = 'fa fa-info';
        this.msg.descripcion = '';
    }

    closeMessage(notification: Notification): void {
        notification.close();
    }

    //FIXED NOTIFICATIONS
    fixedMessage(title: string, faIcon: string = '', message: string): void {
        message = typeof message !== 'string' ? JSON.stringify(message) : message;
        this.msg.titulo = title;
        this.msg.clases = 'alert alert-dismissible font-weight-bold';
        this.msg.icon = faIcon;
        this.msg.descripcion = message;
        this.msg.delayClose = 0;
    }
}
