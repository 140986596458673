﻿import { EntityType } from '../shared/mix/enum';

export enum DataTableJsColumnOrderDirection {
    asc,
    desc,
}
export enum DataTableJsColumnOperation {
    gt,
    gteq,
    eq,
    lteq,
    lt,
    contains,
    eqDay,
}

interface DataTableJsColumnSearch {
    value: string;
    regex?: string; //no implementado en API
}

interface DataTableJsColumnOrder {
    column?: number;
    dir?: DataTableJsColumnOrderDirection;
}

export interface DataTableJsColumn {
    data?: string; //no implementado en API
    name: string;
    searchable: boolean;
    orderable?: boolean; //no implementado en API
    search?: DataTableJsColumnSearch;
    options?: string[];
    operation?: DataTableJsColumnOperation;
}

export interface IDataTableJsResult {
    data: any[];
    recordsTotal: number;
    recordsFiltered: number;
    page: number;
}

export interface IDataTableJsParam {
    showdeleted: boolean;
    draw?: number;
    start: number;
    length: number;
    id?: number;
    type?: number;
    search?: DataTableJsColumnSearch;
    order?: DataTableJsColumnOrder[];
    columns: DataTableJsColumn[];
}
