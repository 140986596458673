﻿import { Component, OnDestroy, OnInit } from '@angular/core';
import { Global } from './shared/mix/global';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationEnd } from '@angular/router';
import { AppGlobalDataService } from './shared/services/app-global-data.service';
import { GuardService } from './shared/services/auth/guard.service';
import { CookieKeys, LocalStorageKey } from './shared/mix/enum';
import { AnalyticsService } from './services/analytics/analytics.service';

import * as JQuery from 'jquery';
import { CheckForUpdateService } from './services/check-version/check-for-update.service';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
    // moduleId: module.id.replace('built', 'app'),
    selector: 'romg-app',
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    loadingRouteConfig: boolean;
    public loadingCanActivate: boolean = true;

    localStorageKeys = LocalStorageKey;

    constructor(
        private _router: Router,
        private _appdata: AppGlobalDataService,
        private analyticsService: AnalyticsService,
        private router: Router,
        private _guardService: GuardService,
        public _checkVersionService: CheckForUpdateService
    ) {
        if (window.location.pathname.length < 2) {
            this._router.navigate(['/' + Global.ADMIN_ZONE_URI]);
        }
    }

    ngOnInit() {
        this._checkVersionService.initializerVersionCheck();

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.analyticsService.routeTracking(event);
            }

            if (event instanceof RouteConfigLoadStart) {
                this.loadingRouteConfig = true;
            } else if (event instanceof RouteConfigLoadEnd) {
                this.loadingRouteConfig = false;
            }
        });

        this._guardService
            .getGuardCanActivateEmitter()
            .pipe(take(1))
            .subscribe((item) => {
                this.loadingCanActivate = false;
            });

        if (this._appdata.isProviderLogged()) {
            this._appdata.initializeUserProvider();
        }

        this._appdata.putClientStorage(CookieKeys.entityChanges, []);
        this._appdata.deleteClientStorage(this.localStorageKeys.activeGroup);

        setTimeout(() => {
            this._appdata.enableTooltip();
        }, 5000);
    }
}
