import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthWeb } from '../auth/auth.web';

@Injectable()
export class GuardService {
    private _guardChange: EventEmitter<boolean> = new EventEmitter();

    constructor(private _authWeb: AuthWeb, private router: Router) {}

    canActivate(): boolean {
        if (!this._authWeb.checkIsLogged()) {
            this.router.navigate(['/login']);
            return false;
        }
        return true;
    }

    emitGuardCanActivate(value: boolean) {
        this._guardChange.emit(value);
    }

    getGuardCanActivateEmitter() {
        return this._guardChange;
    }
}
