import * as production from './environment.prod';

const DEVELOPMENT_API: string = 'https://app-shippingapi-dev-001.azurewebsites.net';
const TESTING_API: string = 'https://app-shippingapi-dev-001-testing.azurewebsites.net';
const API: string = DEVELOPMENT_API;

export const environment = {
    production: false,
    development: true,
    versionControl: false,
    syncfusionApi: 'https://app-shippingauth-dev-001.azurewebsites.net',
    backendApi: API,
    trampWeb: 'https://dev-tramp.romeushipping.com/',
    documentationApi: 'https://shipping-shippy-dev-documentationapi-app.azurewebsites.net',
    InstrumentationKey: '2b738270-dd1c-4055-ac6d-19ea4d438171',
    driverVHost: 'https://pickup',
    shippingWeb: 'https://dev-shippy.romeushipping.com',
    whatsNewWeb: production.environment.whatsNewWeb,
    enabledLogging: true,
    tagAnalytics: undefined,
    businessAudit: false,
    environmentApi: API + '/api/enviroment',
};
