export const ROUTE_PARAMS = {
    LIST: 'list',
    NEW: 'new',
    EDIT: 'edit',
    SHOW: 'show',
    EXTERNAL_HASH: '/#/',
};

export const ROUTES_DEFINITION = {
    // GLOBAL
    BASE: '/',
    LOGIN: 'login',
};
