export const HTTP_REQUEST_CONSTANT = {
    HEADER: {
        APPLICATION_OCTET_STREAM: {
            KEY: 'Accept',
            VALUE: 'application/octet-stream',
        },
        CONTENT_TYPE_JSON: {
            KEY: 'Content-Type',
            VALUE: 'application/json',
        },
        AUTHORIZATION: {
            KEY: 'Authorization',
            VALUE: 'Bearer ',
        },
    },
    BLOB_RESPONSE_TYPE: 'blob',
    OCTET_STREAM_RESPONSE_TYPE: 'octet-stream',
    DOWNLOADABLE_ELEMENT_TAG: 'a',
};
