import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, interval, Observable } from 'rxjs';

const UPDATE_CHECK_INTERVAL = 60 * 1000;
const ENDPOINT_GET_VERSION = `${environment.trampWeb}assets/version.json?t=${new Date().getTime()}`;
const ERROR_GET_VERSION = 'Could not get version.';

@Injectable({
    providedIn: 'root',
})
export class CheckForUpdateService {
    private currentHash: string = '{{POST_BUILD_ENTERS_HASH_HERE}}';
    public refreshWhenNewVersion: boolean = false;

    private detectingNewVersion$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public detectNewVersion$: Observable<boolean> = this.detectingNewVersion$.asObservable();

    constructor(private _http: HttpClient) {}

    /**
     * @description Initialize the version check, checks in every set frequency the version of frontend application
     * @returns void
     */
    public initializerVersionCheck(): void {
        if (environment.versionControl) {
            interval(UPDATE_CHECK_INTERVAL).subscribe(() => {
                this.checkVersion();
            });
        }
    }

    /**
     * @description Will do the call and check if the hash has changed or not
     * @return void
     */
    private checkVersion(): void {
        this._http.get(ENDPOINT_GET_VERSION).subscribe(
            (response: any) => {
                const newHash = response.hash;

                if (this.hasHashChanged(this.currentHash, newHash)) {
                    this.newVersionDetected();
                }
                this.currentHash = newHash;
            },
            (err) => {
                console.error(err, ERROR_GET_VERSION);
            }
        );
    }

    /**
     * @description Checks if hash has changed.
     * @param currentHash
     * @param newHash
     * @returns {boolean}
     */
    private hasHashChanged(currentHash, newHash): boolean {
        if (!currentHash) {
            return false;
        }

        if (currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
            return false;
        }

        if (currentHash === newHash) {
            return false;
        }

        return true;
    }

    /**
     * @description Execute actions when new version detected
     * @returns void
     */
    newVersionDetected(): void {
        this.refreshWhenNewVersion = true;
        this.detectingNewVersion$.next(true);
    }

    /**
     * @description Execute actions when update app
     * @returns void
     */
    updateAvailableNewVersion(): void {
        this.refreshWhenNewVersion = false;
        this.detectingNewVersion$.next(false);
        window.location.reload();
    }
}
