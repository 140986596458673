import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FixedValueDivision, FixedValueDivisionHelper } from 'app/shared/models/fixedValueDivision.model';
import { FixedValueDivisionService } from 'app/shared/services/fixed-value-division/fixed-value-division.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DivisionService {
    private endpoints = {
        getProviderDivisionId: environment.environmentApi + '/division/GetByID?id=',
        getFixedValuesDivisionListByFixedValueCodeList: environment.backendApi + '/api/Division/GetFixedValuesDivisionListByFixedValueCodeList',
    };

    constructor(private _http: HttpClient, private _fixedValueDivisionService: FixedValueDivisionService) {}

    /**
     * @description Call Environemnt API and get division provider id
     * @param  {number} divisionId
     * @returns Observable<number>
     */
    getDivisionProviderId(divisionId: number): Observable<number> {
        return this._http.get(this.endpoints.getProviderDivisionId + divisionId).pipe(
            map((result: any) => {
                return result.Data[0].EntityId;
            })
        );
    }

    /**
     * @description Get fixed value division list according fixed value code list
     * @param  {string[]} fixedValueCodeList
     * @returns Observable<void>
     */
    getFixedValuesDivisionListByFixedValueCodeList(fixedValueCodeList: string[]): Observable<void> {
        return this._http.post(this.endpoints.getFixedValuesDivisionListByFixedValueCodeList, fixedValueCodeList).pipe(
            map((result: any) => {
                const FIXED_VALUE_DIVISION_LIST: FixedValueDivision[] = FixedValueDivisionHelper.mapToObjectList(result.Data[0]);
                this._fixedValueDivisionService.manageFixedValueDivisionLocalStorage(FIXED_VALUE_DIVISION_LIST);
            })
        );
    }
}
