import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConfig, ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class NotifyService {
    globalConfig: GlobalConfig;

    constructor(private _toastrService: ToastrService, private _translateService: TranslateService) {}

    /**
     * @description Generate push info notify
     * @param  {string} message
     * @return void
     */
    infoMessage(message: string): void {
        this._toastrService.info(this._translateService.instant(message), '', { toastClass: 'ngx-toastr notify-info ' });
    }

    /**
     * @description Generate push success notify
     * @param  {string} message
     * @return void
     */
    exitoMessage(message: string, title?: string): void {
        this._toastrService.success(this._translateService.instant(message), title !== undefined ? this._translateService.instant(title) : '', {
            toastClass: 'ngx-toastr notify-success',
        });
    }

    /**
     * @description Generate push warning notify
     * @param  {string} message
     * @return void
     */
    warning(message: string): void {
        if (!message || !message.length) {
            return;
        }

        this._toastrService.warning(this._translateService.instant(message), '', { toastClass: 'ngx-toastr notify-warning' });
    }

    /**
     * @description Generate push warning list notify
     * @param  {string} messageList
     * @return void
     */
    warningList(messageList: string[]): void {
        if (!messageList.length) {
            return;
        }

        for (const message of messageList) {
            this.warning(message);
        }
    }

    /**
     * @description Generate push error notify
     * @param  {string} message
     * @return void
     */
    falloMessage(message: any, title?: string): void {
        this._toastrService.error(this._translateService.instant(message), title !== undefined ? this._translateService.instant(title) : '', {
            toastClass: 'ngx-toastr notify-error',
        });
    }

    /**
     * @description Generate push error notify
     * @param  {string} message
     * @return void
     */
    falloMessageWithoutTranslate(message: any, title?: string): void {
        this._toastrService.error(message, title !== undefined ? this._translateService.instant(title) : '', {
            toastClass: 'ngx-toastr notify-error',
        });
    }

    /**
     * @description Generate fixed push notify used info notify
     * @param  {string} title
     * @param  {string} message
     * @return number
     */
    fixedMessage(title: string, message: string): number {
        this._toastrService.info(this._translateService.instant(message), title, {
            timeOut: 0,
            tapToDismiss: false,
            disableTimeOut: true,
            closeButton: false,
            toastClass: 'ngx-toastr notify-info',
        });
        return this._toastrService.toasts[this._toastrService.toasts.length - 1].toastId;
    }

    /**
     * @description Generate push notifications when start downloading any file
     * @param  {string} title
     * @param  {string} message
     * @return number
     */
    downloadFileMessage(title: string, message: string): number {
        this._toastrService.info(this._translateService.instant(message), title, {
            timeOut: 0,
            closeButton: true,
            toastClass: 'ngx-toastr notify-info notify-download',
        });
        return this._toastrService.toasts[this._toastrService.toasts.length - 1].toastId;
    }

    /**
     * @description Generate push notifications when you finish downloading any file and close the last notification related to the download
     * @param  {string} fileName
     * @param  {number} toastId - Id notification to close
     * @return void
     */
    downloadFileMessageSuccesfully(fileName: string, toastId: number): void {
        this._toastrService.success(this._translateService.instant('ARCHIVO.DOWNLOADS.DOWNLOADED_SUCCESSFULY'), fileName, {
            toastClass: 'ngx-toastr notify-success notify-download',
        });
        this.clearToast(toastId);
    }

    /**
     * @description Generate push notifications when an error has occurred while downloading any file and close the last notification related to the download
     * @param  {string} fileName
     * @param  {number} toastId - Id notification to close
     * @return void
     */
    downloadFileMessageError(message: string, toastId: number, title = ''): void {
        this._toastrService.error(message, title, { toastClass: 'ngx-toastr notify-error notify-download' });
        this.clearToast(toastId);
    }

    /**
     * @description Close all notifies
     * @return void
     */
    clearAll(): void {
        this._toastrService.clear();
    }

    /**
     * @description Close last notify opened
     * @return void
     */
    clearLast(): void {
        const lastNotifyId: number = this._toastrService.toasts[this._toastrService.toasts.length - 1].toastId;
        this._toastrService.clear(lastNotifyId);
    }

    /**
     * @description Close specified notify
     * @param  {number} toastId - Id notification to close
     * @return void
     */
    clearToast(toastId: number): void {
        if (toastId && typeof toastId === 'number' && this._toastrService.toasts.filter((toast) => toast.toastId === toastId).length > 0) {
            this._toastrService.clear(toastId);
        }
    }
}
