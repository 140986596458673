import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[appDebounceClick]',
})
export class DebounceClickDirective implements OnInit, OnDestroy {
    @Input() debounceTime = 700;
    @Output() debounceClick = new EventEmitter();

    private clicks = new Subject();

    private destroy$ = new Subject<void>();

    constructor() {}

    ngOnInit(): void {
        this.clicks.pipe(debounceTime(this.debounceTime), takeUntil(this.destroy$)).subscribe((event) => this.debounceClick.emit(event));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    @HostListener('click', ['$event'])
    clickEvent(event): void {
        event.preventDefault();
        event.stopPropagation();
        this.clicks.next(event);
    }

    @HostListener('dblclick', ['$event'])
    doubleClickEvent(event): void {
        event.preventDefault();
        event.stopPropagation();
        this.clicks.next(event);
    }
}
