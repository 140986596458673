import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReloadAppComponent } from './reload-app.component';

@NgModule({
    imports: [CommonModule],
    declarations: [ReloadAppComponent],
    exports: [ReloadAppComponent],
})
export class ReloadAppModule {}
