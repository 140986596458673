import { throwError as observableThrowError, of as observableOf, forkJoin as observableForkJoin, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { AppGlobalDataService } from '../../shared/services/app-global-data.service';
import { DBOperation } from '../../shared/mix/enum';
import { Global } from '../../shared/mix/global';

import { IFilterProviderReservation } from '../../Interface/provider';

@Injectable()
export class OldReservationService {
    constructor(private _http: HttpClient, private _appdata: AppGlobalDataService) {}

    deleteItem(uri: string, id?: number): Observable<any> {
        let url_id_param = this._appdata.getSafeEndpoint(uri);
        url_id_param = url_id_param.indexOf('Delete') === -1 ? url_id_param + '/Delete' : url_id_param;
        if (url_id_param.indexOf('?Id=') === -1 && id != null) {
            url_id_param += '?Id=' + id;
        }
        let options = this._appdata.getDefaultRequestOptionsHttpClient(DBOperation.delete);
        return this._http.delete(url_id_param, options).pipe(
            map((response: any) => {
                return this._appdata.getResponseHttpClient(response);
            }),
            catchError((response: any) => <any>this.handleError(response))
        );
    }

    deleteItemByUrl(uri: string): Observable<any> {
        let url_id_param = this._appdata.getSafeEndpoint(uri);
        let options = this._appdata.getDefaultRequestOptionsHttpClient(DBOperation.delete);
        return this._http.delete(url_id_param, options).pipe(
            map((response: any) => {
                return this._appdata.getResponseHttpClient(response);
            }),
            catchError((response: any) => <any>this.handleError(response))
        );
    }

    getByUrl(uri: string, firstOne?: boolean, method?: DBOperation): Observable<any> {
        firstOne = firstOne != null ? firstOne : false;
        method = method != null ? method : DBOperation.select;
        let options = this._appdata.getDefaultRequestOptionsHttpClient(method);
        uri = this._appdata.getSafeEndpoint(uri);
        let o: Observable<any>;
        switch (method) {
            case DBOperation.view:
            case DBOperation.select:
                {
                    o = this._http.get(uri, options).pipe(
                        map((response: any) => {
                            return firstOne ? this._appdata.getResponseHttpClient(response)[0] : this._appdata.getResponseHttpClient(response);
                        }),
                        catchError((response: any) => <any>this.handleError(response))
                    );
                }
                break;
            case DBOperation.post_query:
                {
                    o = this._http.post(uri, '', options).pipe(
                        map((response: any) => {
                            return firstOne ? this._appdata.getResponseHttpClient(response)[0] : this._appdata.getResponseHttpClient(response);
                        }),
                        catchError((response: any) => <any>this.handleError(response))
                    );
                }
                break;
            default:
                break;
        }
        return o;
    }

    getOne(uri: string, id?: number, check_endpoint?: boolean, format?: boolean): Observable<any> {
        let options = this._appdata.getDefaultRequestOptionsHttpClient(DBOperation.view);
        let url_id_param = this._appdata.getSafeEndpoint(uri);
        check_endpoint = check_endpoint != null ? check_endpoint : true;
        format = format != null ? format : true;
        if (check_endpoint) {
            url_id_param = url_id_param.indexOf('GetItem') === -1 ? url_id_param + '/GetItem' : url_id_param;
        }
        if (url_id_param.indexOf('?id=') === -1 && id != null) {
            url_id_param += '?id=' + id;
        }
        return this._http.get(url_id_param, options).pipe(
            map((response: any) => {
                return this._appdata.getResponseHttpClient(response)[0];
            }),
            catchError((response: any) => <any>this.handleError(response))
        );
    }

    getByQueryXparamList(uri: string, id_list: any[], format?: boolean): Observable<any> {
        let urlendpoint = this._appdata.getSafeEndpoint(uri);
        let options = this._appdata.getDefaultRequestOptionsHttpClient(DBOperation.view);
        let obs_array: Observable<any>[] = [];
        for (let ide of id_list) {
            let url_id_param = urlendpoint.replace('XparamX', ide);
            obs_array.push(
                this._http.get(url_id_param, options).pipe(
                    map((response: any) => {
                        return this._appdata.getResponseHttpClient(response)[0];
                    }),
                    catchError((response: any) => <any>this.handleError(response))
                )
            );
        }
        return observableForkJoin(obs_array);
    }

    GetCrewWithoutReservationVoyageId(uri: string): Observable<any> {
        let url_id_param = this._appdata.getSafeEndpoint(uri);
        let options = this._appdata.getDefaultRequestOptionsHttpClient(DBOperation.post_query);
        return this._http.get(url_id_param, options).pipe(
            map((response: any) => {
                return this._appdata.getResponseHttpClient(response);
            }),
            catchError((response: any) => <any>this.handleError(response))
        );
    }

    postList(uri: string, list: any[], format?: boolean): Observable<any> {
        let urlendpoint = this._appdata.getSafeEndpoint(uri);
        let options = this._appdata.getDefaultRequestOptionsHttpClient(DBOperation.post_query);
        let obs_array: Observable<any>[] = [];
        for (let item of list) {
            let m = format != null && format ? this._appdata.formatBulkData(item) : item;
            obs_array.push(
                this._http.post(urlendpoint, m, options).pipe(
                    map((response: any) => {
                        return this._appdata.getResponseHttpClient(response);
                    }),
                    catchError((response: any) => <any>this.handleError(response))
                )
            );
        }
        return observableForkJoin(obs_array);
    }

    postQueryXparamList(uri: string, field: string, xlist: any[], ylist?: any[]): Observable<any> {
        ylist = ylist != null ? ylist : [null];
        let urlendpoint = this._appdata.getSafeEndpoint(uri);
        let obs_array: Observable<any>[] = [];
        let options = this._appdata.getDefaultRequestOptionsHttpClient(DBOperation.post_query);
        for (let y = 0; y < ylist.length; y++) {
            for (let i = 0; i < xlist.length; i++) {
                let item = xlist[i];
                let url = urlendpoint;
                url = item instanceof Object && this._appdata.isset(item[field]) ? url.replace('XparamX', item[field]) : url.replace('XparamX', item);
                if (ylist[y] != null) {
                    let item2 = ylist[y];
                    url =
                        item2 instanceof Object && this._appdata.isset(item2[field])
                            ? url.replace('YparamY', item2[field])
                            : url.replace('YparamY', item2);
                }

                obs_array.push(
                    this._http.post(url, '', options).pipe(
                        map((response: any) => {
                            return this._appdata.getResponseHttpClient(response);
                        }),
                        catchError((response: any) => <any>this.handleError(response))
                    )
                );
            }
        }

        if (obs_array.length > 0) {
            return observableForkJoin(obs_array);
        } else {
            return observableOf([]);
        }
    }

    saveItem(uri: string, model: any, check_endpoint?: boolean): Observable<any> {
        check_endpoint = check_endpoint != null ? check_endpoint : true;
        let urlendpoint = this._appdata.getSafeEndpoint(uri);
        urlendpoint = check_endpoint && urlendpoint.indexOf('Put') === -1 ? urlendpoint + '/Put' : urlendpoint;
        let options = this._appdata.getDefaultRequestOptionsHttpClient(DBOperation.update);
        let aux = model !== '' ? this._appdata.formatBulkData(model, 'bbdd', true) : '';
        return this._http.put(urlendpoint, aux, options).pipe(
            map((response: any) => {
                return this._appdata.getResponseHttpClient(response);
            }),
            catchError((response: any) => <any>this.handleError(response))
        );
    }

    rollbackReservation(reservationId: number) {
        let urlendpoint = Global.BASE_RESERVA_FALUA_ENDPOINT + 'RollbackReservationBoatVoyage?reservationId=' + reservationId;
        urlendpoint = this._appdata.getSafeEndpoint(urlendpoint);
        let options = this._appdata.getDefaultRequestOptionsHttpClient(DBOperation.update);
        return this._http.put(urlendpoint, null, options).pipe(
            map((response: any) => {
                return this._appdata.getResponseHttpClient(response);
            }),
            catchError((response: any) => <any>this.handleError(response))
        );
    }

    post(url: string, model: any, format?: boolean): Observable<any> {
        let urlendpoint = this._appdata.getSafeEndpoint(url);
        let options = this._appdata.getDefaultRequestOptionsHttpClient(DBOperation.post_query);
        let m: any;
        if (format != null && format) {
            m = this._appdata.formatBulkData(model, 'bbdd', true);
        } else {
            m = model;
        }

        // let m = (format != null && format) ? this._appdata.formatBulkData(model, 'bbdd', true) : model;
        return this._http.post(urlendpoint, m, options).pipe(
            map((response: any) => {
                return this._appdata.getResponseHttpClient(response);
            }),
            catchError((response: any) => <any>this.handleError(response))
        );
    }

    getBaseService(url: string, param: string): Observable<any> {
        let urlendpoint = this._appdata.getSafeEndpoint(url);
        urlendpoint = urlendpoint.replace('Reservation', 'ProviderServiceGenTariff');
        let options = this._appdata.getDefaultRequestOptionsHttpClient(DBOperation.post_query);
        return this._http.post(urlendpoint + param, {}, options).pipe(
            map((response: any) => {
                return this._appdata.getResponseHttpClient(response);
            }),
            catchError((response: any) => <any>this.handleError(response))
        );
    }

    changeStatus(reservationId: number, newStatus: number): Observable<any> {
        let endpointUrl = Global.RESERVATION_CHANGESTATUS + '?reservationID=' + reservationId + '&status=' + newStatus;
        endpointUrl = this._appdata.getSafeEndpoint(endpointUrl);
        let options = this._appdata.getDefaultRequestOptionsHttpClient(DBOperation.post_query);
        return this._http.put(endpointUrl, null, options).pipe(
            map((response: any) => {
                return this._appdata.getResponseHttpClient(response);
            }),
            catchError((response: any) => <any>this.handleError(response))
        );
    }

    generateReservationFalua(reservationId: number) {
        let endpointUrl = Global.BASE_RESERVA_FALUA_ENDPOINT + 'ActivateReservationBoatVoyage?reservationId=' + reservationId;
        endpointUrl = this._appdata.getSafeEndpoint(endpointUrl);
        let options = this._appdata.getDefaultRequestOptionsHttpClient(DBOperation.update);
        return this._http.put(endpointUrl, null, options).pipe(
            map((response: any) => {
                return this._appdata.getResponseHttpClient(response);
            }),
            catchError((response: any) => <any>this.handleError(response))
        );
    }

    GetProvider(uri: string): Observable<any> {
        let url_id_param = this._appdata.getSafeEndpoint(uri);
        let options = this._appdata.getDefaultRequestOptionsHttpClient(DBOperation.post_query);
        return this._http.get(url_id_param, options).pipe(
            map((response: any) => {
                return this._appdata.getResponseHttpClient(response);
            })
        );
    }

    GetFilterResult(uri: string, filter: IFilterProviderReservation): Observable<any> {
        let url_id_param = this._appdata.getSafeEndpoint(uri);
        let options = this._appdata.getDefaultRequestOptionsHttpClient(DBOperation.post_query);
        return this._http.post(url_id_param, filter, options).pipe(
            map((response: any) => {
                return this._appdata.getResponseHttpClient(response)[0];
            })
        );
    }

    getReservation(uri: string, id?: number, check_endpoint?: boolean, format?: boolean): Observable<any> {
        let options = this._appdata.getDefaultRequestOptionsHttpClient(DBOperation.view);
        let url_id_param = this._appdata.getSafeEndpoint(uri) + 'GetItem?id=' + id;
        check_endpoint = check_endpoint != null ? check_endpoint : true;
        format = format != null ? format : true;
        return this._http.get(url_id_param, options).pipe(
            map((response: any) => {
                return this._appdata.getResponseHttpClient(response)[0];
            })
        );
    }

    setDriverProviderDelegate(uri: string): Observable<any> {
        let body = {};
        let url_id_param = this._appdata.getSafeEndpoint(uri);
        let options = this._appdata.getDefaultRequestOptionsHttpClient(DBOperation.post_query);
        return this._http.post(url_id_param, body, options).pipe(
            map((response: any) => {
                return this._appdata.getResponseHttpClient(response)[0];
            })
        );
    }

    getProviderStates(uri: string): Observable<any> {
        let body = {};
        let url_id_param = this._appdata.getSafeEndpoint(uri);
        let options = this._appdata.getDefaultRequestOptionsHttpClient(DBOperation.post_query);
        return this._http.post(url_id_param, body, options).pipe(
            map((response: any) => {
                return this._appdata.getResponseHttpClient(response);
            })
        );
    }

    private handleError(error: Response) {
        return observableThrowError(error);
    }
}
