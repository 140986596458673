import { Injectable } from '@angular/core';
import { FixedValueDivision } from 'app/shared/models/fixedValueDivision.model';
import { AppGlobalDataService } from '../app-global-data.service';
import { CookieKeys } from 'app/shared/mix/enum';
import { FIXED_VALUE_DIVISION_CONSTANTS } from 'app/constants/fixedValueDivision.constants';

@Injectable({
    providedIn: 'root',
})
export class FixedValueDivisionService {
    constructor(private _appGlobalDataService: AppGlobalDataService) {}

    /**
     *
     * @description Get fixed value division CSGSHOWPO
     * @returns boolean
     */
    getFixedValueDivisionCSGSHOWPO(): boolean {
        return this.getFixedValueDivisionAccordingFixedValueCode(FIXED_VALUE_DIVISION_CONSTANTS.CSGSHOWPO) !== null;
    }

    /**
     *
     * @description Manage fixed value division on locaStorage
     * @param {FixedValueDivision[]} fixedValueDivisionList
     * @returns void
     */
    manageFixedValueDivisionLocalStorage(fixedValueDivisionList: FixedValueDivision[]): void {
        if (!fixedValueDivisionList || !fixedValueDivisionList.length) {
            this._appGlobalDataService.deleteClientStorage(CookieKeys.fixedValueDivision);
            return;
        }

        this._appGlobalDataService.putClientStorage(CookieKeys.fixedValueDivision, fixedValueDivisionList, { hours: 360 }, true);
    }

    /**
     *
     * @private
     * @description Get fixed value division according fixed value code
     * @param {string} fixedValueCode
     * @returns FixedValueDivision | null
     */
    private getFixedValueDivisionAccordingFixedValueCode(fixedValueCode: string): FixedValueDivision | null {
        const FIXED_VALUE_DIVISION_LIST: FixedValueDivision[] = this.getFixedValueDivisionListFromLocalStorage();

        if (!FIXED_VALUE_DIVISION_LIST.length) {
            return null;
        }

        const FIXED_VALUE_DIVISION_FOUND = FIXED_VALUE_DIVISION_LIST.find((fixedValueDivision: FixedValueDivision) => {
            return fixedValueDivision.code.trim().toLowerCase() === fixedValueCode.trim().toLowerCase();
        });

        if (!FIXED_VALUE_DIVISION_FOUND) {
            return null;
        }

        return FIXED_VALUE_DIVISION_FOUND;
    }

    /**
     *
     * @private
     * @description Get fixed value division list from local storage
     * @returns FixedValueDivision[]
     */
    private getFixedValueDivisionListFromLocalStorage(): FixedValueDivision[] {
        const FIXED_VALUE_DIVISION_LIST: FixedValueDivision[] = this._appGlobalDataService.getClientStorage(
            CookieKeys.fixedValueDivision,
            true
        ) as FixedValueDivision[];

        if (!FIXED_VALUE_DIVISION_LIST) {
            return new Array<FixedValueDivision>();
        }

        return FIXED_VALUE_DIVISION_LIST;
    }
}
