import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class BusinessAuditService {
    private businessAuditObservableSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(environment.businessAudit);

    constructor() {}

    /**
     * @description Get hidden element for business audit
     * @returns Observable<boolean>
     */
    getHiddenForBusinessAudit(): Observable<boolean> {
        return this.businessAuditObservableSubject.asObservable();
    }
}
