import { animate, state, style, transition, trigger, group } from '@angular/animations';

export const routerTransitionAnimation = [
    trigger('routerTransition', [
        state('void', style({})),
        state('*', style({})),
        transition(':enter', [style({ transform: 'translateY(100%)' }), animate('0.5s ease-in-out', style({ transform: 'translateY(0%)' }))]),
        transition(':leave', [style({ transform: 'translateY(0%)' }), animate('0.5s ease-in-out', style({ transform: 'translateY(-100%)' }))]),
    ]),
];

export const SlideInOutAnimation = [
    trigger('slideInOut', [
        state(
            'in',
            style({
                'max-height': '500px',
                'opacity': '1',
                'visibility': 'visible',
            })
        ),
        state(
            'out',
            style({
                'max-height': '0px',
                'opacity': '0',
                'visibility': 'hidden',
            })
        ),
        transition('in => out', [
            group([
                animate(
                    '400ms ease-in-out',
                    style({
                        'opacity': '0',
                    })
                ),
                animate(
                    '600ms ease-in-out',
                    style({
                        'max-height': '0px',
                    })
                ),
                animate(
                    '700ms ease-in-out',
                    style({
                        'visibility': 'hidden',
                    })
                ),
            ]),
        ]),
        transition('out => in', [
            group([
                animate(
                    '1ms ease-in-out',
                    style({
                        'visibility': 'visible',
                    })
                ),
                animate(
                    '600ms ease-in-out',
                    style({
                        'max-height': '500px',
                    })
                ),
                animate(
                    '800ms ease-in-out',
                    style({
                        'opacity': '1',
                    })
                ),
            ]),
        ]),
    ]),
];
