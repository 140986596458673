import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { IUser } from 'app/interface/user';
import { CookieKeys } from 'app/shared/mix/enum';
import { Global } from 'app/shared/mix/global';

@Injectable({
    providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
    constructor(private _http: HttpClient) {}

    /**
     * @description Error handler
     * @param  {any} error
     */
    handleError(error: any) {
        if (error) {
            if (error.message.toString().indexOf('#') > -1) {
                const errorMessage: string = 'Exception controlled with ' + error.message;
                console.log('%c ' + errorMessage, 'background: #000fff; color: #ffffff');
                this.sendException(errorMessage);
                return;
            }

            console.error(this.getStackTrace(error.stack));
        }
    }

    /**
     * @description Send exception to server
     * @param  {string} errorMessage
     */
    sendException(errorMessage: string) {
        // if (!environment.production) {
        //     return;
        // }
        let user: IUser = JSON.parse(localStorage.getItem('usr_data'));
        let endpoint: string = Global.SendException + '?origin=TrampingWeb&message=' + errorMessage + '&user=' + user.UserName;
        return this._http.post(endpoint, null).subscribe((response) => {
            return this.getResponseException(response);
        });
    }

    /**
     * @description Send exception list to server
     * @param  {string} errorMessageList
     */
    sendExceptionList(errorMessageList: string[]) {
        if (errorMessageList && errorMessageList.length) {
            this.sendException(JSON.stringify(errorMessageList));
            localStorage.removeItem(CookieKeys.exceptions);
        }
    }

    foreachArrayAndDeletelastitem(array: any[]) {
        for (let i = 0; i < array.length; i++) {
            array.pop();
        }
    }

    /**
     * @description Manager response exception
     * @param  {any} response
     * @returns response
     */
    public getResponseException(response: any) {
        if (response !== null) {
            return response.Data ? response.Data : response;
        }
        return [];
    }

    /**
     * @description Get clear stack trace
     * @param  {string} stack
     * @returns string
     */
    getStackTrace(stack: string): string {
        const stackTrace: string[] = [];

        stack
            .replace(/[\r\n]/gm, '')
            .split(' at ')
            .forEach((trace, index) => {
                if (index === 0) {
                    stackTrace.push(`Error: ${trace.trim().replace('TypeError: ', '')}`);
                }

                if (index > 0) {
                    stackTrace.push(`${index} - ${trace.trim()}`);
                }
            });

        return stackTrace.join('\n');
    }
}
